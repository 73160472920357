/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@angular/cdk/overlay-prebuilt.css';

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #0F191E;
    opacity: 0.85 !important;
  }
}

.bio-modal-panel {
  position: relative !important;
}

html {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  height: 100%;
}

@font-face {
  font-family: Omnes;
  src: url('assets/fonts/Omnes.ttf');
}
